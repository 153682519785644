.tox-tinymce {
  border: 0px !important;
}

.tox-editor-header {
  position: absolute;
  top: 0;
}

.tox-tinymce {
  //border: 5px solid red !important;
  height: calc(100vh - 8rem) !important;
}

.tox-toolbar__primary {
  justify-content: center;
}

.tox-edit-area {
  //padding: 1rem 2rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1200px !important;
  //border: 5px solid red !important;
}
