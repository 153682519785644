@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.notification.slideIn {
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.notification.slideOut {
  transform: translateX(150%);
  flex: 0;
}

.notification {
  max-width: 430px;
  max-height: 200px;
  overflow: hidden;
  padding: 12px 48px 12px 12px;
  z-index: 99;
  font-weight: bold;
  position: relative;
  transition: transform 0.3s ease-out;
  min-width: 280px;

  background-color: white;
}

.notification:not(:last-child) {
  margin-bottom: 8px;
}

.notification.info {
}

.notification.success {
  /* background-color: white; */
}

.notification.warning {
  /* background-color: #ff9800; */
}

.notification.error {
  /* background-color: #f44336; */
}

.notification .closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

.notification {
  color: theme("colors.gray.900");
}

.notification .closeButton {
  color: theme("colors.gray.400");
  transition: color 200 ease-in-out;
}

.notification .closeButton:hover {
  color: theme("colors.gray.900");
}

/* Shriking container for a smoother removal of notifications */
.container {
  overflow: visible;
  max-height: 200px;
  transition: max-height 0.3s ease-out;
}

.container:not(:last-child) {
  margin-bottom: 8px;
}

.container.shrink {
  max-height: 0;
}
