.rti--container {
  --rti-bg: "#fff",
  --rti-border: "#ccc",
  --rti-main: "#3182ce",
  --rti-radius: "0.375rem",
  --rti-s: "0.5rem",
  --rti-tag: "#edf2f7",
  --rti-tag-remove: "#e53e3e";
  border: none !important;
  outline: none !important;
  padding: 0.3rem 0.5rem;
}

.rti--container:focus-within {
  border: none !important;
  outline: none !important;
  box-shadow:none;
}

.rti--tag{
  background-color: theme('colors.primary.100');
  color: theme('colors.primary.500');
  padding: 0.35rem 0.5rem;
  font-weight: 600;
  font-size: 0.8rem;
}

.rti--tag button{
  font-size: 0.8rem;
  color: theme('colors.primary.300');
}

.rti--tag button:hover{
  color: theme('colors.primary.500');
}

.empty .rti--input{
  width: 100%;
}

.invalid .rti--input{
  background-color: theme('colors.red.100');
}

.rti--input{
  padding-left: 0;
  padding: 0.25rem;
  font-weight: 500;
  color: theme('colors.gray.900');
  background-color: transparent;
  /* border: 1px solid red; */
  width: unset;
  border-radius: 0.1rem;
}

.rti--input:focus{
  /* border: 2px solid red !important; */
  box-shadow: none !important;
  outline: none !important;
}

.rti--input::placeholder{
  font-weight: 600;
  color: theme('colors.gray.400');
}