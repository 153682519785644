@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poor+Story&display=swap");

@layer base {
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 200;
    src: url("/public/fonts/PlusJakartaSans-ExtraLight.ttf");
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 300;
    src: url("/public/fonts/PlusJakartaSans-Light.ttf");
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    src: url("/public/fonts/PlusJakartaSans-Regular.ttf");
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
    src: url("/public/fonts/PlusJakartaSans-Medium.ttf");
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    src: url("/public/fonts/PlusJakartaSans-SemiBold.ttf");
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    src: url("/public/fonts/PlusJakartaSans-Bold.ttf");
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 800;
    src: url("/public/fonts/PlusJakartaSans-ExtraBold.ttf");
    font-style: normal;
    font-display: swap;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body::-webkit-scrollbar {
  width: 0px;
  background-color: transparent !important;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

body::-webkit-scrollbar-track {
  background-color: transparent !important;
}

html,
body {
  scrollbar-width: none !important;
}

:root {
  scrollbar-width: none;
}

.disable-scrollbars {
  scrollbar-width: none; /* Newer Firefox and not confirmed */
  overflow: -moz-scrollbars-none; /* Older Firefox*/
}

/* Custom style tooltips ---------------------------------*/
.tippy-box[data-theme~="copymatic"] {
  background-color: theme("colors.primary.900");
  font-size: theme("fontSize.xs");
  font-weight: theme("fontWeight.medium");
  line-height: 1.6em;
  color: white;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.tippy-box[data-theme~="copymatic"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: theme("colors.primary.900");
}
.tippy-box[data-theme~="copymatic"][data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: theme("colors.primary.900");
}
.tippy-box[data-theme~="copymatic"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: theme("colors.primary.900");
}
.tippy-box[data-theme~="copymatic"][data-placement^="right"] > .tippy-arrow::before {
  border-right-color: theme("colors.primary.900");
}
/* Custom style tooltips ---------------------------------*/

/* Hide the default up/down error in number input */
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  #quantityInput {
    -moz-appearance: textfield !important;
  }
}

/* iframe styles */
@layer base {
  .coffee {
    :root {
      --bg-primary: 255, 255, 255;
      --bg-secondary: 245, 246, 247;
      --bg-tertiary: 238, 238, 238;

      --text-primary: 0, 0, 0;
      --text-secondary: 56, 63, 74;
      --text-tertiary: 255, 255, 255;
    }
  }
}

:root {
  --primary-color: #f5f5f5;
  --secondary-color: #555555;
  --background-color: #ff0000;
}

.icon__primary {
  fill: var(--primary-color);
}

.icon__secondary {
  fill: var(--secondary-color);
}

.icon__background {
  fill: var(--background-color);
}

.bg-black-to-transparent {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, transparent 95%);
}
